$colours: (
  green: #51abe0,
  olive: #A0CC3A,
  purple:#252832,
  blue: #37A9E0,
  orange:#E67F25,
  grey: #4C494B,
  white: #ffffff
);

$font-primary: Helvetica,Arial,Helvetica Neue,sans-serif;

body {
  margin: 0;
  font-family: Helvetica,Arial,Helvetica Neue,sans-serif;
  background-color: #F8F8F8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.logos {
  align-items: center;
  justify-items: center;
  padding: 0px 3vw;
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr)!important;
    margin-bottom: 30px;
  }
  img {
    max-height: 60px;
    max-width: 180px;
    @media only screen and (max-width: 1000px) {
      max-height: 60px;
      max-width: 100px;
    }
  }
}

.container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1250px) minmax(0, 1fr);
  >div {
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
}


.bg {
  @each $name, $colour in $colours {
    &-#{$name} {
      background-color: #{$colour}!important;
    }
  }
}

.colour {
  @each $name, $colour in $colours {
    &-#{$name} {
      color: #{$colour}!important;
    }
  }
}


.ui {
  &-title {
    color: map-get($colours,grey);
    font-family: Helvetica,Arial,Helvetica Neue,sans-serif;
    font-weight: 700;
    // letter-spacing: ;
    &-mega {
      @extend .ui-title;
      font-size: 2.5em;
      @media only screen and (max-width: 600px){
        font-size: 1em;
      }
    }
    &-one {
      @extend .ui-title;
      font-size: 2.25em;
      @media only screen and (max-width: 600px){
        font-size: 1.3em;
      }
    }
    &-two {
      @extend .ui-title;
      font-size: 1.75em;
      @media only screen and (max-width: 600px){
        font-size: 1em;
      }
    }
    &-three {
      @extend .ui-title;
      font-size: 1.5em;
      @media only screen and (max-width: 600px){
        font-size: 1.1em;
      }
    }
    &-four {
      @extend .ui-title;
      font-size: 1.25em;
      @media only screen and (max-width: 600px){
        font-size: 1em;
      }
    }
    &-five {
      @extend .ui-title;
      font-size: 1.1em;
      @media only screen and (max-width: 600px){
        font-size: .9em;
      }
    }
    &-sub {
      @extend .ui-title;
      color: var(--ui-text-lighter);
      font-weight: 300;
    }
  }
  &-label {
    color: map-get($colours,grey);
    font-family: $font-primary;
    font-size: 1em;
    font-weight: 400;
  }
  &-body {
    color: map-get($colours,grey);
    font-family: $font-primary;
    font-size: .9em;
    font-weight: 400;
    line-height: 1.6;
  }
  &-link {
    color: map-get($colours, blue);
    font-family: $font-primary;
    text-decoration: none;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    // font-size: 18px;
    &:after {
      content: '';
      position: absolute;
      width: 0; height: 1.5px;
      display: block;
      right: 0;
      background: rgba(map-get($colours, blue),.2);
      transition: width .4s ease;
    }
    &:hover:after {
      width: 100%;
      left: 0;
    }
  }
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

.text-center {
  text-align: center;
}


.col {
  display: grid;
  @for $i from 1 to 13 {
    &-#{$i} {
      display: grid;
      grid-template-columns: repeat($i, 1fr);

      @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
      }

      &-min {
        display: grid;
        grid-template-columns: repeat($i, min-content);
      }

      &-max {
        display: grid;
        grid-template-columns: repeat($i, max-content);
      }
    }
    &-start-#{$i} {
      grid-column-start: $i;
    }
    &-end-#{$i} {
      grid-column-end: $i + 1;
    }
    &-start-md-#{$i} {
      grid-column-start: $i;

      @media only screen and (max-width: 750px) {
        grid-column-start: 1;
      }
    }
    &-end-md-#{$i} {
      grid-column-end: $i + 1;

      @media only screen and (max-width: 750px) {
        grid-column-end: 1;
      }
    }
  }
}

.grid {
  display: grid;
  &-center {
    &-top {
      display: grid;
      align-items: start;
      justify-content: center;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-content: center;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-content: center;
    }
  }
  &-left {
    &-top {
      display: grid;
      align-items: start;
      justify-content: left;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-content: left;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-content: left;
    }
  }
  &-right {
    &-top {
      display: grid;
      align-items: start;
      justify-items: right;
    }
    &-center {
      display: grid;
      align-items: center;
      justify-items: right;
    }
    &-bottom {
      display: grid;
      align-items: end;
      justify-items: right;
    }
  }
}

.gap {
  @for $i from 1 to 100 {
    &-#{$i} {
      gap: 0px + $i;
    }
  }
}


.padding {
  @for $i from 0 to 100 {
      &-#{$i} { padding: 0px + $i!important };
      &-top-#{$i} { padding-top: 0px + $i!important };
      &-bottom-#{$i} { padding-bottom: 0px + $i!important };
      &-left-#{$i} { padding-left: 0px + $i!important };
      &-right-#{$i} { padding-right: 0px + $i!important };
  }
}

.margin {
  @for $i from 0 to 100 {
      &-#{$i} { margin: 0px + $i!important };
      &-top-#{$i} { margin-top: 0px + $i!important };
      &-bottom-#{$i} { margin-bottom: 0px + $i!important };
      &-left-#{$i} { margin-left: 0px + $i!important };
      &-right-#{$i} { margin-right: 0px + $i!important };
  }
}

.container {
  display: grid;
  &-small {
    @extend .container;
    grid-template-columns: minmax(5%, 1fr) minmax(0, 500px) minmax(5%, 1fr);
  }
  &-medium {
    @extend .container;
    grid-template-columns: minmax(5%, 1fr) minmax(0, 1000px) minmax(5%, 1fr);
  }
  &-large {
    @extend .container;
    grid-template-columns: minmax(5%, 1fr) minmax(0, 1200px) minmax(5%, 1fr);
  }
}

.ui {
  &-input, &-select, &-textarea {
    -webkit-appearance: none;
    color: map-get($colours,grey);
    font-family: $font-primary;
    border: 1px solid lightgrey;
    font-size: 1em;
    padding: 12px 18px;
    outline: 0;
    background: var(--ui-bg);
    resize: none;
    transition: .2s;
    border-radius: 8px;
    width:100%;
    &:hover:not([disabled]), &:focus {
      background-color: var(--ui-container);
    }
    &.warning {
      border: 1px solid map-get($colours,orange);
    }
  }
  &-input-group {
    display: grid;
    grid-template-columns: auto 150px;
    button {
      border-radius: 0 8px 8px 0;
      height: 65px;
      font-size: 18px;
    }
    input {
      border-radius: 8px 0 0 8px;
      font-size: 18px;
    }
    @media only screen and (max-width: 600px){
      display: flex;
      flex-direction: column;
      button, input {
        border-radius: 8px;
      }
      button {
        margin-top: 10px;
      }
    }
  }
  &-select {
    background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" data-prefix="far" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="" d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: calc(100% - 14px);
    background-clip: padding-box;
    padding-right:40px;
    &[disabled]{
      opacity: .5;
    }
  }
  &-btn {
    text-decoration: none;
    -webkit-appearance: none;
    background-color: rgba(255,255,255,.2);
    border: .5px solid white;
    font-family: $font-primary;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 300;
    outline:0;
    cursor: pointer;
    transition: .2s;
    &:hover {
      box-shadow: 0px 4px 10px rgba(0,0,0,0.115);
    }
    &:active {
      box-shadow: 0px 0px 4px rgba(0,0,0,0.1);
    }
    &-primary {
      @extend .ui-btn;
      background-color: map-get($colours,green);
      color:black;
      border: 0;
      padding: 18px 25px;
      font-weight: 700;
    }
    &-compact {
      @extend .ui-btn;
      padding: 12px 12px;
      font-size: 14px;
      border-radius: 8px;
    }
  }
}

.ui-input:-webkit-autofill,
.ui-input:-webkit-autofill:hover, 
.ui-input:-webkit-autofill:focus, 
.ui-input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px var(--ui-bg) inset !important;
}
.ui-input:-webkit-autofill {
  -webkit-text-fill-color: map-get($colours,grey) !important;
}
.ui-input::placeholder {
  color: var(--ui-text-lighter);
}

.footer {
  border-top: 1px solid #eeeeee;
}

@media only screen and (max-width: 1000px){
  .mobile-hide {
    display: none;
  }
  .header {
    justify-items: center;
  }
  .footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      margin-top:20px;
      text-align: center;
    }
  }
  .padding-60 {
    padding: 30px!important;
  }
  .gap-80 {
    gap: 40px;
  }
  .gap-60 {
    gap:20px;
  }
  .banner {
    padding: 30px 24px!important;
    gap:20px!important;
    .margin-bottom-80 {
      margin:0!important;
    }
    img {
      width: 120px!important;
    }
  }
}